@import "./Variables";
@import './placeholders';

.alertCard {
    margin: 0 5px 0 0;
    padding: 5px 10px;
    height: 100px;
    margin-bottom: 10px; }

.alertCardHeader {
    display: flex;
    justify-content: space-between; }

.alertContent {
    font-weight: bold;
    font-size: 1.3rem; }

.elongated-text {
    height: 100%!important; }

.pillContainer {
    margin-bottom: 5px;
    width: 70%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    @extend %scrollbar;
    &::-webkit-scrollbar-thumb {
        border: 7px solid white!important; } }

.truncated-text {
    width: 85%;
    height: 27px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 1.3rem;
 }    // margin-bottom: 5px
