@import './Variables';
@import './placeholders';

.accent-button {
  background-color: $secondary-color;
  @extend %button;
  &:hover {
    background-color: #8EA7C2; } }

button {
  &:disabled {
    background-color: $light-background;
    color: $valert-gray;
    cursor: not-allowed;
    @extend %button;
    &:hover {
      cursor: not-allowed; } } }

.cancel-button {
  background-color: #d9534f;
  margin: 1rem 1rem 0 0;
  @extend %button;
  &:hover {
    background-color: #f7879a; }
  &:focus {
    background-color: darken(#f7879a, 10);
    border: darken($primary-color, 30) solid 3px; } }

.disabled-button {
  background-color: $light-background;
  color: lightgray;
  @extend %button;
  &:hover {
    background-color: #0D5096; }
  &:focus {
    background-color: darken($primary-color, 10);
    border: darken($primary-color, 30) solid 3px; } }

.outlined-button {
  background-color: transparent;
  border: 2px solid $light-background;
  border-radius: 6px;
  padding: 8px 15px;
  margin: 10px;
  &:focus {
      outline: none; }
  &:hover {
    background-color: $light-background;
    cursor: pointer; } }

.outlinedRequired-button {
  background-color: transparent;
  border: 2px solid red;
  border-radius: 6px;
  padding: 8px 15px;
  // margin: 10px
  &:focus {
    outline: none; }
  &:hover {
    background-color: darken($valert-white, 10%);
    cursor: pointer; } }


.primary-button {
  background-color: $primary-color;
  @extend %button;
  &:hover {
    background-color: #A9CEFF; }
  &:focus {
    background-color: darken($primary-color, 10);
    border: darken($primary-color, 30) solid 3px; } }

.secondary-button {
  background-color: $accent-color;
  @extend %button;
  color: white;
  &:hover {
    background-color: #0D5096; }
  &:focus {
    background-color: darken($primary-color, 10);
    border: darken($primary-color, 30) solid 3px; } }

.zeroLeftMargin {
  margin-left: 0 !important; }
