@import "./Variables";

.date-separator-container {
    margin-bottom: 10px;
    width: 99%;
    display: flex;
    align-items: center;
    justify-content: center; }

.date-separator-pill {
    align-items: center;
    border: 1px solid $valert-black;
    border-radius: 16px;
    color: $valert-black;
    display: flex;
    height: 16px;
    justify-content: center;
    min-width: 130px;
    width: 40%;
    @media screen and ( max-width: 930px ) {
        width: 60%;
        max-width: 150px; }
    @media screen and ( max-width: 768px ) {
        width: 40%; }
    @media screen and ( max-width: 570px ) {
        width: 100%; } }

.date-separator-time {
    color: $valert-black;
    @media screen and ( max-width: 1100px ) {
        font-size: .75rem; } }

.date-separator-wing-left {
    color: $valert-black;
    border-bottom: 1px solid $valert-black;
    width: 280px; }

.date-separator-wing-right {
    color: $valert-black;
    border-bottom: 1px solid $valert-black;
    width: 280px; }
