@import './Variables';

.addUser-button-container {
    margin-left: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: -10px; }

.addUser-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px; }

.addUser-dropdown-container {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 10px;
    .btn {
        text-align: left;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        @media screen and ( max-width: 800px ) {
            font-size: .6rem; } }
    .dropdown-menu.show {
        width: 150px!important;
        overflow: auto!important;
        position: relative !important;
        top: 0 !important;
        transform: none !important; }
    .dropdown-toggle {
        width: 158px; } }

.admin-checkbox {
    cursor: pointer;
    margin-top: 20px;
    margin-right: 8px;
    margin-bottom: 20px; }

.addUser-input-container {
    width: 60%;
    font-weight: bold; }

.add-remove-channels-container {
    margin-top: 30px; }

.add-remove-channels-container {
    margin-top: 30px; }

.add-user-title {
    font-weight: bold; }

.btn-secondary {
    background-color: $accent-color;
    &:active {
        background-color: $accent-color; } }
