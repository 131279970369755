@import './Variables.sass';

%button {
    cursor: pointer;
    padding: 8px 15px;
    border-style: none;
    border-radius: 6px;
    height: $button-default-height;
    &:focus {
        outline: none; } }

%scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $accent-color transparent;
    &::-webkit-scrollbar {
        margin: 5px;
        width: 8px;
        border-radius: 3px; }
    &::-webkit-scrollbar-track {
        background: transparent; }
    &::-webkit-scrollbar-thumb {
        background: $accent-color;
        border: 2px solid white;
        border-radius: 20px; } }
