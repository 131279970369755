@import './Variables.sass';

.appStyleHeader {
    width: 100vw;
    height: 100px;
    margin-top: -20px;
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: auto;
    padding-bottom: 20px;
    display: flex; }

.confirmStylesBtn {
    width: 228px;
    @media only screen and (max-width: 768px) {
        width: 392px !important; } }

.chrome-picker {
    margin-bottom: 10px !important;
    @media only screen and (max-width: 768px) {
        width: 390px !important; } }


.chromePickerAndButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    @media only screen and (max-width: 768px) {
        display: none; } }

.chromePickerAndButtonMobileContainer {
    display: none;
    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-items: center;
        margin-bottom: 40px; } }


.defaultLogo {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto; }

.filepond--wrapper {
    width: 392px;
    @media only screen and (max-width: 870px) {
        width: 350px; }
    @media only screen and (max-width: 768px) {
        width: 390px; } }


.uploadedLogo {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto; }

.uploadSection {
    margin-bottom: 20px; }

.leftHandStylePageSection {
    display: flex;
    flex-direction: column;
    width: 40%;
    @media only screen and (max-width: 768px) {
        width: 90%; } }

.sectionHeadingMobile h1 {
    text-decoration: none;
    @media screen and (max-width: 950px) {
        font-size: 1.75rem !important; }
    @media only screen and (max-width: 768px) {
        text-decoration: underline; } }

.StylesPageContainer {
   width: 90%;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   margin-left: 100px;
   @media only screen and (max-width: 768px) {
       width: 80%;
       flex-direction: column;
       margin-right: auto;
       margin-left: auto; } }

.stylesPageHeading {
    width: 80%;
    height: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    @media only screen and (max-width: 768px) {
        height: 0px; } }

.stylePageHeaderText {
    @media only screen and (max-width: 768px) {
        font-size: 1rem; } }

.stylePageParagraphText {
    font-weight: normal !important;
    margin-top: 40px;
    @media only screen and (max-width: 980px) {
        font-size: 1.5rem; }
    @media only screen and (max-width: 768px) {
        display: none; } }


.stylePageMargin {
    margin-left: 0px !important; }


.chromePickerCustomization {
    margin-top: 14px; }

.filepond--credits {
    color: $valert-black; }

