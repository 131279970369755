.input-file-label {
    cursor: pointer; }

.smallBottomMargin {
    margin-bottom: 5px !important; }

.uploadFileBtn {
    margin-top: 15px; }

.filepond--wrapper {
    width: 434px !important;
    @media screen and (max-width: 768px) {
        max-width: 434px !important;
        @media screen and (max-width: 548px) {
            max-width: 418px !important; } } }
