.donutContainer {
  height: 250px;
  width: 250px; }

.donutStatsContainer {
  @media screen and ( min-width: 549px ) {
    display: flex;
    flex-direction: row;
    // change back to space-between
    justify-content: center;
    margin: 2rem; }
  @media screen and ( max-width: 548px ) {
    display: flex;
    flex-direction: column;
    align-items: center; } }

.donutText {
  width: 185px;
  text-align: center;
  margin: -160px auto;
  font-size: 1.25rem;
  p {
    margin-bottom: 5px; } }

.donutHeading {
  font-weight: bold;
  font-size: 1.25rem; }
