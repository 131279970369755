.deleteRole {
    background-color: #d9534f;
    width: 100px;
    margin: 10px;
    @extend %button;
    &:hover {
        background-color: #f7879a; } }

.editRole {
    width: 100px;
    background-color: $primary-color;
    margin: 10px;
    @extend %button;
    &:hover {
      background-color: #A9CEFF; } }

.headerButton {
    margin: 0;
    width: 100px;
    background-color: $primary-color;
    &:hover {
      background-color: #A9CEFF; }
    @extend %button; }

.mainTable {
    background: #f4f4f4;
    margin-top: 5rem; }

.mainTableDark {
    background: #4F4F4F;
    margin-top: 5rem; }

.mainTableHeaderDark {
    color: white; }
