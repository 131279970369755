@import "./Variables";

input {
    &:disabled {
        padding: 10px 0px 10px 10px;
        border-radius: 3px;
        background-color: #efefef;
        border: 1px solid #efefef;
        position: relative;
        cursor: 'not-allowed'; }
    &::placeholder {
        color: #C4C4C4; } }

.input {
    padding: 10px 0px 10px 10px;
    border-radius: 6px;
    position: relative;
    &-field {
        @extend .input;
        border: 1px solid #959595;
        cursor: text; } }

.input-checkbox {
    margin-left: 5px;
    vertical-align: middle; }

.input-field-container {
    display: flex;
    flex-direction: column; }

.input-label {
    color: black;
    font-family: renner;
    font-size: $form-label; }

.requiredLabelRed:after {
    content: " *";
    color: red; }

.requiredInput:required {
  border: 2px solid red;
  &:valid {
    border: 1px solid #ced4da; } }

.requiredConfirmPasswordInput {
    border: 2px solid red !important; }
