@import './placeholders';

.activeUserNumber {
    font-size: 2rem;
    text-align: center;
    align-items: center; }

.activeUserTitle {
    font-size: 1.2rem;
    text-align: center;
    align-items: center;
    padding: 0 20px; }

.alertContainer {
    height: 70vh;
    overflow: auto;
    min-width: 250px;
    @extend %scrollbar;
    @media screen and ( max-width: 950px ) {
        height: 65vh; }
    @media screen and ( max-width: 768px ) {
        width: 100%; } }

.alertNavButtons {
    z-index: 10;
    .primary-button {
        padding: 5px 10px !important;
        margin: 5px 10px 10px 10px; } }

.alertSection {
    width: 60%;
    min-width: 250px;
    @media screen and ( max-width: 950px ) {
        width: 100%; }
    @media only screen and (max-width: 768px) {
        width: 100%; } }

.alertSectionInfo {
    display: flex;
    height: 50px;
    min-width: 250px;
    margin-top: 1rem;
    align-content: center;
    justify-content: space-between;
    @media screen and ( max-width: 768px ) {
        width: 100%!important; }
    p {
        margin: auto 5px;
        @media screen and ( max-width: 768px ) {
            max-width: 175px;
            margin: 10px 0 0 5px; } } }

.analyticsCard {
    margin-top: 2rem;
    text-align: center;
    align-items: center;
    p {
        margin: 0; } }

.analyticsCardNumber {
    font-size: 2rem;
    text-align: center;
    align-items: center; }

.analyticsCardTitle {
    margin-top: .5rem!important;
    font-size: 1.2rem; }

.analyticsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }

.CalendarDay {
    &:focus {
      background-color: darken($primary-color, 10);
      border: darken($primary-color, 30) solid 3px; } }

.calendarSection {
    display: flex;
    flex-flow: column wrap;
    width: 320px;
    margin-top: 50px;
    height: 300px; }

.dashboardContainer {
    height: 90%; }

.deviceStatsContainer {
  display: flex;
  flex-direction: row; }

.dropdown-item {
    padding: .25rem .75rem!important; }

.headingUnderline {
    height: 2px;
    width: 100%;
    background-color: black; }

.registeredDevicesNumber {
    font-size: 2rem;
    text-align: center;
    align-items: center; }

.registeredDevicesTitle {
    font-size: 1.2rem;
    text-align: center;
    align-items: center; }

.rightSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 40%;
    @media only screen and ( max-width: 950px ) {
        display: none;
        width: 0px; } }

.search-filter {
    width: 100%;
    min-width: 250px;
    @media screen and ( max-width: 950px ) {
        width: 100%; }
    @media only screen and (max-width: 768px) {
        width: 100%; } }

.search-filter-pills {
    overflow-x: scroll !important;
    overflow-y: hidden;
    min-width: 250px;
    width: 100%;
    max-width: 300px;
    @extend %scrollbar;
    &::-webkit-scrollbar {
        margin: 5px;
        width: 8px;
        border-radius: 3px;
        height: 1px; }
    display: inherit; }

.search-filter-selector {
    width: 150px; }

.statCard {
  height: 150px;
  width: 150px;
  margin: 10px 5px;
  padding: 10px; }

.statContainer {
    height: 75vh;
    overflow: auto;
    min-width: 250px;
    @extend %scrollbar; }

.statSection {
    height: 83%; }

.sectionHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

.upperContainer {
    display: flex;
    justify-content: flex-start; }

