@import './Variables';


.toggle-button {
  cursor: pointer;
  background-color: $valert-white;
  border: 2px solid darken($primary-color, 5%);
  border-radius: 50px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  margin-left: -10px;
  min-width: unset;
  box-sizing: border-box;
  outline: none !important;
  transition: all 0.3s ease;
  outline: none !important;
  z-index: 1;
  &:hover {
      transform: scale(1.2); }
  &:focus {
      background-color: darken($primary-color, 10);
      border: darken($primary-color, 30) solid 3px; } }

.toggle-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }


.toggle-container {
  width: 110px;
  height: 26px !important;
  background-color: $primary-color;
  border: 3px solid $primary-color;
  cursor: pointer;
  user-select: none;
  border-radius: 50px;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 12px;
  &:focus {
      background-color: darken($primary-color, 10);
      border: darken($primary-color, 30) solid 3px; }
  @media screen and (max-width: 375px) {
    margin-right: 12px; } }

.word-or-icon {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    color: $valert-black !important;
    justify-content: flex-start;
    font-size: 11px;
    &:hover {
        cursor: pointer; } }

.word-or-icon-select {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    color: $valert-black !important;
    justify-content: flex-end;
    margin-right: -2px;
    font-size: 10px;
    &:hover {
        cursor: pointer; } }

.alert {
  justify-content: flex-end;
  align-items: center;
  padding: 0 !important;
  position: initial !important;
  &:hover {
    cursor: pointer; } }

.fa-exclamation {
    color: $valert-black;
    font-size: 10px {
     align-self: center; } }

.fa-chart-bar {
    color: $valert-black;
    font-size: 10px;
    align-self: center; }

.stat {
    justify-content: flex-start;
    align-items: center;
    &:hover {
        cursor: pointer; } }

.m-left {
    margin-left: 6px;
    margin-right: -6px; }

.m-right {
    margin-right: 6px;
    margin-left: -4px; }
