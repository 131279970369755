@import "./Button";
@import "./Variables";
@import "./Navigation";
@import "./InputField";
@import "./placeholders";
@import "./Status";
@import "./Login";
@import "./Pill";
@import "./Copyright";
@import "./Calendar";
@import "./AlertCard";
@import "./DonutChart";
@import "./Dashboard";
@import "./SingleAlertAnalytics";
@import "./DateSeparator";
@import "./Table";
@import "./Admin";
@import "./Sender";
@import "./AddUser";
@import "./Card";
@import "./Token";
@import "./FileManager";
@import "./toggleButton";
@import "./notFoundPage";
@import "./Styles";

html, body {
    height: 100vh;
    position: relative;
    p, label, button {
      font-family: $body-font, sans-serif; }
    h1, h2, h3, h4, h5 {
      font-family: $header-font, sans-serif;
      font-weight: bold; } }

h1 {
  font-size: 2rem; }

#root, #App {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

* {
  box-sizing: border-box; }

.addEditModalHeader {
  h2 {
    font-size: $form-header; } }

.addSectionHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.app-panel {
  padding-bottom: 14px;
  clear: both;
  width: 100%;
  @media screen and (max-width:768px) {
    display: flex;
    flex-direction: column; } }

.form-group {}

.checkbox-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

.hideOnDesktop {
  @media screen and (min-width: 769px) {
    display: none !important; } }

.hideOnPhones {
  @media screen and (max-width: 768px) {
    display: none !important; } }

.minorPrint {
  font-size: .75rem;
  font-style: italic; }

.outerContainer {
  margin: 1rem;
  margin-left: 95px;
  @media screen and (min-width: 2100px) {
    margin: 1rem auto;
    width: 1900px; }
  @media screen and (max-width: 768px) {
    margin-top: 75px;
    margin-left: 20px; } }

.outerFlexContainer {
  display: flex;
  @extend .outerContainer;
  @media screen and (max-width: 950px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px; } }

.outerFormContainer {
  margin: 1rem; }

.sectionHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h1 {
    text-decoration: underline;
    @media screen and ( max-width: 950px ) {
      font-size: 1.75rem!important; } } }

.side-panel {
  float: left;
  min-height: 100vh;
  height: 100%;
  z-index: 1032;
  @media screen and (max-width: 950px) {
    min-height: 0!important; } }

.wrapper-container-admin {
  display: flex;
  flex-direction: column;
  height: initial;
  position: relative; }

.wrapper-container {
  display: flex;
  flex-direction: column;
  position: relative; }

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch; }

.hidden {
  display: none; }

.outlineNone {
  outline: none; }





