@import './Variables';
@import './placeholders';

.fieldMarginFix {
  margin-right: 146px; }

.login-group {
  margin: 2rem; }

.fullScreenBackground {
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column; } }

hr {
  margin-top: 0; }

h1 {
  font-weight: 400; }

.input-login-password,
.input-login-user {
    border: 0;
    outline: 0;
    border-bottom: 1px solid $accent-color;
    cursor: text; }

.loginBackground {
  background-color: $login-background;
  background: $login-background url('../assets/loginBackground.jpg') no-repeat center center fixed;
  opacity: 1.65;
  background-size: cover;
  height: 100vh;
  padding-top: 42vh;
  text-align: center;
  display:flex {}
  width: 50vw;
  z-index: 30;
  @media (max-width: 450px) {
    width: 100vw;
    height: 30vh;
    padding-top: 4vh; } }
.loginBackground::before {
  background-color: $login-background; }

.loginButton {
  @extend %button;
  background-color: $accent-color;
  color: $valert-white;
  width: 100%;
  margin-left: 0!important;
  margin-top: 30px!important;
  &:hover {
    background-color: $accent_color;
    color: $light-background; } }

.loginContainer {
  margin-top: 30vh;
  justify-content: center;
  overflow-y: auto;
  verticle-align: center;
  width: 50vw;
  @media (max-width: 450px) {
    margin-top: 10vh;
    width: 100%;
    height: 70vh; } }

.loginLogo {
  height: 25%;
  margin-bottom: 5%;
  margin-top: 10%; }

.loginSubtitle {
  color: black;
  width: 200px;
  margin-left: 2rem;
  margin-bottom: 2rem;
  p {
    font-size: 1rem;
    font-weight: lighter; } }

.loginSubWelcome {
  color: white;
  font-size: 1.75rem;
  font-weight: 240;
  margin-top: 20px; }

.loginTitle {
  color: $accent-color;
  h1 {
    font-size: 1.5rem;
    margin-bottom: .15rem; }
  margin-left: 2rem; }

.loginWelcome {
  font-size: 2rem;
  color: white; }

.titleLine {
  border-top: 1px solid $accent_color;
  width: 80px;
  margin-left: 0; }
