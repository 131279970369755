@import './placeholders';

.generateToken {
    margin-top: 10px;
    width: 100%; }

.tokenButton {
    margin-left: 7px;
    display: none; }

.tokenHeader {
    display: flex;
    border-bottom: 2px solid lightgray;
    flex-flow: column wrap;
    align-items: flex-start;
    padding-bottom: 22px; }

.tokenHeaderDark {
    display: flex;
    border-bottom: 2px solid white;
    flex-flow: column wrap;
    align-items: flex-start;
    padding-left: 2rem;
    padding-top: 2rem; }

.tokenHeading {
    margin: 0px!important; }

.tokenInput {
    margin: 5px 0px 5px 0px;
    border: 1px solid gray;
    border-radius: 5px;
    background: transparent;
    width: 100%;
    height: 40px; }

.tokenName {
    display: flex;
    justify-content: space-between; }

.tokens {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-flow: column wrap;
    &:hover {
        svg {
            display: inline-block; } } }

.tokensOdd {
    @extend .tokens;
    background: #f8f8f8; }

.tokensOddDark {
    @extend .tokens;
    background: #6C6B6B; }

.tokenSection {
    word-break: break-all;
    height: 60vh;
    overflow: auto;
    @extend %scrollbar; }

.tokenTable {
    display: flex;
    flex-flow: column wrap;
    @media screen and ( max-width: 950px ) {
        margin-top: 1rem; } }

.tokenTableDark {
    background: #4F4F4F;
    margin-top: 5rem;
    height: 70%;
    color: white; }
