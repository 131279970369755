@import "./Variables";
@import './placeholders';

.channelSelector {
  .dropdown-toggle {
    width: 200px;
    margin: 5px 0px;
    @media only screen and (max-width: 768px) {
      width: 100%; } }
  .dropdown-menu.show {
    width: 200px;
    max-height: none!important;
    @media only screen and (max-width: 768px) {
      width: 100%;
      position: relative!important;
      transform: none!important; }
    button:focus {
      outline: none; }
    button:active {
      background-color: white; } } }

.pillsContainer {
  display: flex;
  margin: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  @extend %scrollbar;
  &::-webkit-scrollbar-thumb {
    border: 7px solid white!important; } }

.recipientLabel {
  margin-right: 5px; }

.selectorCheck {
  width: 16px;
  margin-right: 10px; }

.selectorItemGroup {
  display: flex; }

.senderBody {
    display: flex;
    justify-content: space-between;
    height: 100%;
    @media only screen and (max-width: 768px) {
        display: block; } }

.senderButton {
  @extend %button;
  width: 100px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 5px 20px!important; }

.senderContainer {
  margin: 50px 0 40px 1rem;
  padding: 5px 10px;
  width: 70vw;
  height: 70vh;
  textarea {
    border: none;
    resize: none;
    height: 52vh;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  @media screen and ( max-width: 1150px ) {
    width: 70vw; }
  @media screen and ( max-width: 1060px ) {
    width: 70vw; }
  @media screen and ( max-width: 950px ) {
    width: 65vw; }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    margin: 0px; } }

.senderLeft {
  width: 200px;
  @media only screen and (max-width: 768px) {
    width: 100%; } }
