@import './Variables.sass';

.notFoundPageBackground {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: auto;
    width: 100%;
    min-width: 545px;
    overflow: hidden; }

.notFoundPage {
    width: 60%;
    height: auto;
    @media screen and (max-height: 360px) {
        width: 40%;
        margin-top: 30px; }
    @media screen and (max-width: 768px) {
        width: 80%; } }

.notFoundPageWrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  background-color: $not-found-page-background-color; }

.notFoundContentWrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 50%;
    @media screen and (max-height: 360px) {
        height: 100%; } }

.notFoundText {
    width: 600px;
    text-align: center;
    color: lighten($valert-white, 10%);
    @media screen and (max-width: 768px) {
        font-size: 1rem; } }

.notFoundButton {
    width: 149.39px;
    margin-right: auto;
    margin-left: auto;
    background-color: $valert-white !important;
    @media screen and (max-width: 768px) {
         font-size: .9rem; } }

.hidden-h1 {
    overflow: hidden;
    height: 0;
    width: 0; }



