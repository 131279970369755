@import './placeholders';


.singleAlertCard {
    margin-top: 2rem;
    text-align: center;
    align-items: center;
    width: 250px;
    margin: 10px 5px;
    padding: 10px;
    p {
        margin: 0; } }

.singleAlertCardNumber {
    font-size: 4rem;
    text-align: center;
    align-items: center; }

.singleAlertCardNumberFont {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    align-items: center; }

.singleAlertContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }

.singleAlertContentContainer {
  @media screen and ( min-width: 549px ) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem; }
  @media screen and ( max-width: 548px ) {
    display: flex;
    flex-direction: column;
    align-items: center; } }


.singleAlertHeaderCard {
    margin-top: 2rem;
    padding-left: 1em;
    padding-right: 1em;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    p {
        margin: 0; } }

.singleAlertHeader {
    width: 100%;
    display: flex;
    justify-content: space-between; }

.singleAlertPillContainer {
    margin-top: .5rem!important;
    width: 70%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    text-align: right;
    @extend %scrollbar;
    &::-webkit-scrollbar-thumb {
        border: 7px solid white!important; } }

.singleAlertStatContainer {
    display: flex;
    flex-direction: row;
    // change back to space-between
    justify-content: center;
    margin: 2rem; }

.singleAlertTitle {
    margin-top: .5rem!important;
    font-size: 2rem;
    font-weight: bold;
    width: 100%; }
