@import './Variables';

.calendar-heading {
    display: flex;
    justify-content: center; }

.calendar-year {
    margin: '0 3px'; }

// overriding react dates styling

.CalendarDay {
    outline: none; }

.CalendarDay__default {
    border: none; }

.DayPickerNavigation_button__default {
    border: none; }

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background-color: $primary-color; }

.DayPickerNavigation_svg__horizontal {
    fill: $secondary-color; }

.DayPicker__hidden {
    visibility: visible; }

.DayPicker_transitionContainer {
    height: 300px !important; }


